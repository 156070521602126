























































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import { User } from '@/types'
import { ShareCodeByEmailToSelfCommand, TellAFriendApi, TellAFriendCodeDto, TellAFriendGift, UpdateGiftCommand } from '@/api'
import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'

interface InputFields {
  gift: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  }
})
export default class TellAFriend extends mixins(State) {
  public tellAFriendCode: TellAFriendCodeDto|undefined = undefined;
  public shareLink = '';
  public sentEmail = false;

  inputFields: InputFields = {
    gift: {
      name: 'gift',
      value: '',
      required: true,
      inline: true,
      type: 'image-radio',
      options: [
        {
          label: 'Handdoek',
          value: TellAFriendGift.Towel,
          image: 'action/towel.png'
        },
        {
          label: 'Sporttas',
          value: TellAFriendGift.GymBag,
          image: 'action/bag.jpg'
        },
        {
          label: 'Dopper',
          value: TellAFriendGift.WaterBottle,
          image: 'action/bottle.jpg'
        }
      ],
      placeholder: 'Cadeau',
      errors: []
    }
  };

  hasDirtyInput = false;

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscription
  }

  public async emailToSelf () {
    this.$gtag.event('Sporten werkt', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'Actiepagina',
      value: 'Email share'
    })
    const api = new TellAFriendApi()
    const fields: ShareCodeByEmailToSelfCommand = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0
    }
    try {
      this.state = this.states.LOADING
      await api.tellAFriendShareCodeByEmailToSelf(fields)

      this.state = this.states.COMPLETE
      this.sentEmail = true
    } catch (error) {
      this.state = this.states.ERROR
      this.sentEmail = false
    }
  }

  public onWhatsappClick () {
    this.$gtag.event('Sporten werkt', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'Actiepagina',
      value: 'Whatsapp share'
    })
  }

  public async updateGift () {
    this.clearErrors()
    if (!this.validateInput()) {
      return
    }

    const api = new TellAFriendApi()
    const fields: UpdateGiftCommand = {
      gift: this.inputFields.gift.value as TellAFriendGift
    }
    try {
      this.state = this.states.LOADING
      this.clearErrors()
      await api.tellAFriendUpdateGift(fields)
      this.hasDirtyInput = false

      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR

      this.setErrors(error.response.data.errors)

      this.$root.$emit('scrollToTop')
    }
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  public inputChange () {
    this.hasDirtyInput = true
  }

  private validateInput () {
    let valid = true

    Object.keys(this.inputFields).forEach((key: string) => {
      let input
      if (Array.isArray(this.$refs[key])) {
        input = (this.$refs[key] as Element[])[0]
      } else {
        input = this.$refs[key]
      }
      valid = (input as FormInput).validate() && valid
    })

    return valid
  }

  get hasErrors () {
    let hasError = false
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      hasError = hasError || fieldValue.errors.length > 0
    })
    return hasError
  }

  mounted () {
    this.setState(this.states.LOADING)

    const api = new TellAFriendApi()

    api.tellAFriendGetTellAFriendCode(this.currentUser.subscription?.subscriptionId || 0)
      .then((data) => {
        this.tellAFriendCode = data.data
        // Disabled because there's no choice this year: this.inputFields.gift.value = this.tellAFriendCode.gift?.toString() || ''
        // Remove default https-port from uri, it is ugly.
        const encodedLink = encodeURIComponent((this.tellAFriendCode.registrationFlowLoginUri || '').replace(':443/', '/'))
        this.shareLink = `https://api.whatsapp.com/send?text=Hi collega, wist je dat het deze maand dé Sporten Werkt actiemaand is bij Bedrijfsfitness Nederland?
        %0a%0aDankzij ${encodeURIComponent(this.currentUser.projectInformation?.name || '')} kan je – net als ik – genieten van korting op een sportabonnement. Als jij je in oktober inschrijft voor bedrijfsfitness en daarbij mijn actiecode gebruikt, ontvang je een limited edition rugtas. Je gebruikt hiervoor de volgende link: ${encodedLink}.
        %0a%0aVeel sportplezier! Sportieve groet,
        %0a%0a${encodeURIComponent(this.currentUser.employeeDetails?.firstName || '')}`
        this.setState(this.states.COMPLETE)
      })
      .catch(() => {
        this.setState(this.states.ERROR)
      })
  }
}
