import { render, staticRenderFns } from "./TellAFriend.vue?vue&type=template&id=6294a668&scoped=true&"
import script from "./TellAFriend.vue?vue&type=script&lang=ts&"
export * from "./TellAFriend.vue?vue&type=script&lang=ts&"
import style0 from "./TellAFriend.vue?vue&type=style&index=0&id=6294a668&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6294a668",
  null
  
)

export default component.exports